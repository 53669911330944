import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import AWS from 'aws-sdk';

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.14px;
  color: #6f6f6f;
  margin-bottom: 10px;
`;

const InputCommonStyle = `
  height: 40px;
  width: 100%;
  line-height: 30px;
  font-size: 18px;
  color: #000000;
  border-width: 0;
  &:focus {
    outline: inherit;
  }
`;



const Form = ({ lang = 'ja' }) => {
  return (
    <Flex >
      <p>
        <font size="5" css={"color:red;"}>海外渡航前のPCR検査のご予約を希望される方へ</font>
        <br />
        <br />
        <br />
        <a href="https://reserva.be/hirahatacovidtest/reserve?mode=service_staff&search_evt_no=2ceJwzMTU0tzABAARUAT4"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
          中国へ渡航される方
        </a>
        <a href="https://www.hirahata-clinic.or.jp/covid-pcr-cn/"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
          赴华核酸检测详细（中文）
        </a>
        <br />
        <br />
        <br />
        <a href="https://reserva.be/hirahatacovidtest/reserve?mode=service_staff&search_evt_no=4feJwzMTUxNjABAAREATU"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
          その他の国へ渡航される方
        </a>
        <br />
        <br />
        <br />
        抗体検査割引キャンペーン中
        <a href="../anti-body-campaign"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
          こちら
        </a>から
        <br />
        <br />
        <br />
        <font size="4">PCR検査についてのお問い合わせは以下のページよりお願いいたします。</font>
        <br />
        <br />
        <a href="../contact-pcr/"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
          PCRお問い合わせ
        </a>
        <br />
        <br />
        <br />
        <font size="4">
          保険・公費のPCR検査をご希望の方は
          <a href="https://reserva.be/hirahatacovidtest/reserve?mode=service_staff&search_evt_no=fbeJwzMTI3MrUAAARMAT0"
            css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
            こちら
          </a>
          から
          <br />
          <br />
          検査の概要について知りたい方は
          <a href="../covid-pcr"
            css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
            こちら
          </a>
          をご覧ください。
          <br />
          <br />
        </font>
      </p>
    </Flex>
  );
};

export default Form;