import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import SubLayout from '../components/SubLayout';
import Form from '../components/FormReservationPCR';

const Contact = () => (
  <SubLayout topTitle="PCR検査予約">
    <Flex center>
      <FlexItem
        css={`
          margin-top: 80px;
          margin-bottom: 80px;
          width: 96%;
        `}
      >
        <Form />
      </FlexItem>
    </Flex>
  </SubLayout>
);

export default Contact;
